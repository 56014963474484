import React from "react"
import { Layout, Header } from "../components"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Privacyverklaring = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Privacyverklaring"
        description="Privacyverklaring To Be On The Web"
      />
      <Header />
      <div className="max-w-screen-xl mx-auto p-4 lg:px-8">
        <div className="-mx-4 flex max-w-5xl flex-wrap">
          <div className="w-full px-4 md:w-2/3">
            <h2 class="font-heading mb-2 text-4xl leading-tight">
              Over ons privacybeleid
            </h2>
            <div className="prose prose-zinc">
              <p>
                To Be On The Web geeft veel om uw privacy. Wij verwerken daarom
                uitsluitend gegevens die wij nodig hebben voor (het verbeteren
                van) onze dienstverlening en gaan zorgvuldig om met de
                informatie die wij over u en uw gebruik van onze diensten hebben
                verzameld. Wij stellen uw gegevens nooit voor commerciële
                doelstellingen ter beschikking aan derden.
              </p>
              <p>
                Dit privacybeleid is van toepassing op het gebruik van de
                website en de daarop ontsloten dienstverlening van To Be On The
                Web. De ingangsdatum voor de geldigheid van deze voorwaarden is
                24/04/2018, met het publiceren van een nieuwe versie vervalt de
                geldigheid van alle voorgaande versies.
              </p>
              <p>
                Dit privacybeleid beschrijft welke gegevens over u door ons
                worden verzameld, waar deze gegevens voor worden gebruikt en met
                wie en onder welke voorwaarden deze gegevens eventueel met
                derden kunnen worden gedeeld. Ook leggen wij aan u uit op welke
                wijze wij uw gegevens opslaan en hoe wij uw gegevens tegen
                misbruik beschermen en welke rechten u heeft met betrekking tot
                de door u aan ons verstrekte persoonsgegevens. Als u vragen
                heeft over ons privacybeleid kunt u contact opnemen met onze
                contactpersoon voor privacyzaken, u vindt de contactgegevens aan
                het einde van ons privacybeleid.
              </p>
              <h2>Over de gegevensverwerking</h2>
              <p>
                Hieronder kan u lezen op welke wijze wij uw gegevens verwerken,
                waar wij deze (laten) opslaan, welke beveiligingstechnieken wij
                gebruiken en voor wie de gegevens inzichtelijk zijn.
              </p>
              <h3>Webhosting</h3>
              <h4>Siteground, Netlify en Contabo</h4>
              <p>
                Wij nemen webhosting- en e-maildiensten af van Siteground,
                Netlify en Contabo. Siteground, Netlify en Contabo verwerken
                persoonsgegevens namens ons en gebruiken uw gegevens niet voor
                eigen doeleinden. Wel kunnen deze partijen metagegevens
                verzamelen over het gebruik van de diensten. Dit zijn geen
                persoonsgegevens. Siteground, Netlify en Contabo hebben passende
                technische en organisatorische maatregelen genomen om verlies en
                ongeoorloofd gebruik van uw persoonsgegevens te voorkomen.
                Siteground, Netlify en Contabo is op grond van de overeenkomst
                tot geheimhouding verplicht.
              </p>
              <h4>Cloudflare, Open Provider en Mijn Oxxa</h4>
              Wij nemen diensten met betrekking tot domeinnamen af van
              Cloudflare, Open Provider en Mijn Oxxa. Cloudflare, Open Provider
              en Mijn Oxxa verwerken persoonsgegevens namens ons en gebruiken uw
              gegevens niet voor eigen doeleinden. Wel kunnen deze partijen
              metagegevens verzamelen over het gebruik van de diensten. Dit zijn
              geen persoonsgegevens. Cloudflare, Open Provider en Mijn Oxxa
              hebben passende technische en organisatorische maatregelen genomen
              om verlies en ongeoorloofd gebruik van uw persoonsgegevens te
              voorkomen. Cloudflare, Open Provider en Mijn Oxxa is op grond van
              de overeenkomst tot geheimhouding verplicht.
              <h2 className="mt-4">Facturatie en boekhouden</h2>
              <h3>Acumulus</h3>
              <p>
                Voor het bijhouden van onze administratie en boekhouding maken
                wij gebruik van de diensten van Acumulus. Wij delen uw naam,
                adres en woonplaatsgegevens en details met betrekking tot uw
                dienst die u afneemt bij To Be On The Web. Deze gegevens worden
                gebruikt voor het administreren van verkoopfacturen.
              </p>
              <p>
                Uw persoonsgegevens worden beschermd verzonden en opgeslagen.
                Acumulus is tot geheimhouding verplicht en zal uw gegevens
                vertrouwelijk behandelen. Acumulus gebruikt uw persoonsgegevens
                niet voor andere doeleinden dan hierboven beschreven.
              </p>
              <h2>Bewaartermijnen</h2>
              <p>
                Wij bewaren uw gegevens zolang u cliënt van ons bent. Dit
                betekent dat wij uw klantprofiel bewaren totdat u aangeeft dat u
                niet langer van onze diensten gebruik wenst te maken. Als u dit
                bij ons aangeeft zullen wij dit tevens opvatten als een
                vergeetverzoek. Op grond van toepasselijke administratieve
                verplichtingen dienen wij facturen met uw (persoons)gegevens te
                bewaren, deze gegevens zullen wij dus voor zolang de
                toepasselijke termijn loopt bewaren. Medewerkers hebben echter
                geen toegang meer tot uw cliëntprofiel en documenten die wij
                naar aanleiding van uw opdracht hebben vervaardigd.
              </p>
              <h2>Uw rechten</h2>
              <p>
                Op grond van de geldende Nederlandse en Europese wetgeving heeft
                u als betrokkene bepaalde rechten met betrekking tot de
                persoonsgegevens die door of namens ons worden verwerkt. Wij
                leggen u hieronder uit welke rechten dit zijn en hoe u zich op
                deze rechten kunt beroepen.
              </p>
              <p>
                In beginsel sturen wij om misbruik te voorkomen afschriften en
                kopieën van uw gegevens enkel naar uw bij ons reeds bekende
                e-mailadres. In het geval dat u de gegevens op een ander
                e-mailadres of bijvoorbeeld per post wenst te ontvangen, zullen
                wij u vragen zich te legitimeren. Wij houden een administratie
                bij van afgehandelde verzoeken, in het geval van een
                vergeetverzoek administreren wij geanonimiseerde gegevens. Alle
                afschriften en kopieën van gegevens ontvangt u in de
                machineleesbare gegevensindeling die wij binnen onze systemen
                hanteren. U heeft te allen tijde het recht om een klacht in te
                dienen bij de Autoriteit Persoonsgegevens als u vermoedt dat wij
                uw persoonsgegevens op een verkeerde manier gebruiken.
              </p>
              <h2>Inzagerecht</h2>
              <p></p>U heeft altijd het recht om de gegevens die wij (laten)
              verwerken en die betrekking hebben op uw persoon of daartoe
              herleidbaar zijn, in te zien. U kunt een verzoek met die strekking
              doen aan onze contactpersoon voor privacyzaken. U ontvangt dan
              binnen 30 dagen een reactie op uw verzoek. Als uw verzoek wordt
              ingewilligd sturen wij u op het bij ons bekende e-mailadres een
              kopie van alle gegevens met een overzicht van de verwerkers die
              deze gegevens onder zich hebben, onder vermelding van de categorie
              waaronder wij deze gegevens hebben opgeslagen.
              <h2>Rectificatierecht</h2>
              <p>
                U heeft altijd het recht om de gegevens die wij (laten)
                verwerken en die betrekking hebben op uw persoon of daartoe
                herleidbaar zijn, te laten aanpassen. U kunt een verzoek met die
                strekking doen aan onze contactpersoon voor privacyzaken. U
                ontvangt dan binnen 30 dagen een reactie op uw verzoek. Als uw
                verzoek wordt ingewilligd sturen wij u op het bij ons bekende
                e-mailadres een bevestiging dat de gegevens zijn aangepast.
              </p>
              <h2>Recht op beperking van de verwerking</h2>
              <p>
                U heeft altijd het recht om de gegevens die wij (laten)
                verwerken die betrekking hebben op uw persoon of daartoe
                herleidbaar zijn, te beperken. U kunt een verzoek met die
                strekking doen aan onze contactpersoon voor privacyzaken. U
                ontvangt dan binnen 30 dagen een reactie op uw verzoek. Als uw
                verzoek wordt ingewilligd sturen wij u op het bij ons bekende
                e-mailadres een bevestiging dat de gegevens tot u de beperking
                opheft niet langer worden verwerkt.
              </p>
              <h2>Recht op overdraagbaarheid</h2>
              <p>
                U heeft altijd het recht om de gegevens die wij (laten)
                verwerken en die betrekking hebben op uw persoon of daartoe
                herleidbaar zijn, door een andere partij te laten uitvoeren. U
                kunt een verzoek met die strekking doen aan onze contactpersoon
                voor privacyzaken. U ontvangt dan binnen 30 dagen een reactie op
                uw verzoek. Als uw verzoek wordt ingewilligd sturen wij u op het
                bij ons bekende e-mailadres afschriften of kopieën van alle
                gegevens over u die wij hebben verwerkt of in opdracht van ons
                door andere verwerkers of derden zijn verwerkt. Naar alle
                waarschijnlijkheid kunnen wij in een dergelijk geval de
                dienstverlening niet langer voortzetten, omdat de veilige
                koppeling van databestanden dan niet langer kan worden
                gegarandeerd.
              </p>
              <h3>Recht van bezwaar en overige rechten</h3>
              <p>
                U heeft in voorkomende gevallen het recht bezwaar te maken tegen
                de verwerking van uw persoonsgegevens door of in opdracht van
                Natural Pet Shop. Als u bezwaar maakt zullen wij onmiddellijk de
                gegevensverwerking staken in afwachting van de afhandeling van
                uw bezwaar. Is uw bezwaar gegrond dat zullen wij afschriften
                en/of kopieën van gegevens die wij (laten) verwerken aan u ter
                beschikking stellen en daarna de verwerking blijvend staken. U
                heeft bovendien het recht om niet aan geautomatiseerde
                individuele besluitvorming of profiling te worden onderworpen.
                Wij verwerken uw gegevens niet op zodanige wijze dat dit recht
                van toepassing is. Bent u van mening dat dit wel zo is, neem dan
                contact op met onze contactpersoon voor privacyzaken.
              </p>
              <h3>Recht op vergetelheid</h3>
              <p>
                Indien u wenst vergeten te worden (het verwijderen van uw NAW /
                persoonsgegevens uit ons archief). Stuur dan een mail naar onze
                contactpersoon voor privacyzaken. Wij verwijderen binnen 30
                dagen u gegevens. Dit bevat geen gegevens die we verplicht
                moeten bewaren in verband met administratieve, wettelijke of
                beveiligings doeleinden.
              </p>
              <h2>Cookies</h2>
              <h3>Google Analytics</h3>
              <p>
                Via onze website worden cookies geplaatst van het Amerikaanse
                bedrijf Google, als deel van de “Analytics”-dienst. Wij
                gebruiken deze dienst om bij te houden en rapportages te krijgen
                over hoe bezoekers de website gebruiken. Deze verwerker is
                mogelijk verplicht op grond van geldende wet- en regelgeving
                inzage te geven in deze gegevens. Wij verzamelen informatie over
                uw surfgedrag en delen deze gegevens met Google. Google kan deze
                informatie in samenhang met andere datasets interpreteren en op
                die manier uw bewegingen op het internet volgen. Google gebruikt
                deze informatie voor het aanbieden van onder andere gerichte
                advertenties (Adwords) en overige Googlediensten en producten.
              </p>
              <h3>Cookies van derde partijen</h3>
              <p>
                In het geval dat softwareoplossingen van derde partijen gebruik
                maken van cookies is dit vermeld in deze privacyverklaring.
              </p>
              <h2>Wijzigingen in het privacybeleid</h2>
              <p>
                Wij behouden te allen tijde het recht ons privacybeleid te
                wijzigen. Op deze pagina vindt u echter altijd de meest recente
                versie. Als het nieuwe privacybeleid gevolgen heeft voor de
                wijze waarop wij reeds verzamelde gegevens met betrekking tot u
                verwerken, dan brengen wij u daarvan per e-mail op de hoogte.
              </p>
              <h2>Contactgegevens</h2>
              <p>
                To Be On The Web
                <br />
                Kievit 12b -14
                <br /> 5111HD Baarle-Nassau
                <br /> Nederland
                <br /> T 06-294 78 385
                <br /> E{" "}
                <a href="mailto: info@tobeontheweb.nl">info@tobeontheweb.nl</a>
                <br /> Contactpersoon voor privacyzaken A.K. Hage
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Privacyverklaring
